import getToken from "../tools/getToken";

const deleteSite = async (id) => {
  return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    method: "DELETE",
  }).then((r) => {
    if (r.ok) return true;
    throw new Error(r.statusText);
  });
};

export default deleteSite;
