import getToken from "../tools/getToken";

const getSites = async () => {
  return fetch(`${process.env.REACT_APP_API_URL}/sites`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(r.statusText);
  });
};

export default getSites;
