import { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import SitesListResult from "../components/sites/SitesListResult";
import SitesListToolbar from "../components/sites/SitesListToolbar";
import SiteForm from "../components/sites/SiteForm";
import getSites from "../requests/sites/getSites";
import addUpdateSite from "../requests/sites/addUpdateSite";
import deleteSite from "../requests/sites/deleteSite";
import { useSnackbar } from "notistack";

const Sites = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");

  const handleOpenForm = (row) => {
    setSelectedSite(row || "");
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedSite("");
  };

  const handleSubmit = ({ url, description }, id) => {
    setIsLoading(true);
    addUpdateSite({ url, description }, id)
      .then((r) => {
        setSites(
          id ? sites.map((site) => (site.id === id ? r : site)) : [...sites, r]
        );
        setIsLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(
          "Erro ao enviar formulário, atualize a página e tente novamente.",
          { variant: "error" }
        );
      });
    handleCloseForm();
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteSite(id)
      .then((r) => {
        setSites((oldData) => oldData.filter((site) => site.id !== id));
        setIsLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(
          "Erro ao apagar site, atualize a página e tente novamente.",
          { variant: "error" }
        );
      });
    handleCloseForm();
  };

  useEffect(() => {
    setIsLoading(true);
    getSites()
      .then((data) => {
        setSites(data);
        setIsLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(
          "Erro ao carregar os sites, atualize a página e tente novamente.",
          { variant: "error" }
        );
      });
  }, [enqueueSnackbar]);

  return (
    <>
      <SiteForm
        open={isFormOpen}
        onClose={handleCloseForm}
        onSubmit={handleSubmit}
        data={selectedSite}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <SitesListToolbar onOpenForm={handleOpenForm} isLoading={isLoading} />
          <Box sx={{ mt: 3 }}>
            <SitesListResult
              rows={sites}
              onEdit={handleOpenForm}
              onDelete={handleDelete}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Sites;
