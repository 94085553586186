import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import UserListResult from "../components/users/UserListResult";
import UserListToolbarfrom from "../components/users/UserListToolbar";
import ConfirmDelete from "../components/users/ConfirmDelete";
import getUsers from "../requests/users/getUsers";
import deleteUser from "../requests/users/deleteUser";
import { useSnackbar } from "notistack";

const Users = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleDelete = (id) => {
    setOpenConfirmationDialog(id);
  };

  const handleConfirmDelete = () => {
    setIsLoading(true);
    deleteUser(openConfirmationDialog)
      .then((r) => {
        setUsers((oldData) =>
          oldData.filter((user) => user.id !== openConfirmationDialog)
        );
        setIsLoading(false);
        setOpenConfirmationDialog("");
      })
      .catch(() => {
        enqueueSnackbar(
          "Erro ao apagar usuário, atualize a página e tente novamente.",
          { variant: "error" }
        );
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getUsers()
      .then((data) => {
        setUsers(data);
        setIsLoading(false);
      })
      .catch(() => {
        enqueueSnackbar(
          "Erro ao carregar usuários, atualize a página e tente novamente.",
          { variant: "error" }
        );
      });
  }, [enqueueSnackbar]);

  console.log(users);

  return (
    <>
      <ConfirmDelete
        open={!!openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog("")}
        onDelete={handleConfirmDelete}
        isLoading={isLoading}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <UserListToolbarfrom />
          <Box sx={{ mt: 3 }}>
            <UserListResult users={users} onDelete={handleDelete} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Users;
