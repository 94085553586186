import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material";

const SitesListResult = ({ rows, onEdit, onDelete, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  return (
    <Card sx={{ minWidth: 1050, overflowX: "auto" }} {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Site</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Data do registro</TableCell>
                <TableCell>Data de atualização</TableCell>
                <TableCell>Editar</TableCell>
                <TableCell>Apagar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(0, limit).map((site) => (
                <TableRow hover key={site.id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {site.url}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography color="textPrimary" variant="body2">
                        {site.description}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <b>
                      {new Date(site.created_at).toLocaleDateString() +
                        " - " +
                        new Date(site.created_at).toLocaleTimeString()}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {new Date(site.updated_at).toLocaleDateString() +
                        " - " +
                        new Date(site.updated_at).toLocaleTimeString()}
                    </b>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => onEdit(site)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => onDelete(site.id)}>
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage="Linhas por página"
      />
    </Card>
  );
};

SitesListResult.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default SitesListResult;
