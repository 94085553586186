import React from "react";
import ReactDOM from "react-dom";
import Main from "./main/Main";
import "./theme/base.css";

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root")
);
