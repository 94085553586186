/* global google */
import { useCallback, useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { useAuth, treatLoginUserInfo } from "./AuthProvider";
import { Navigate, useLocation } from "react-router-dom";
import decode from "jwt-decode";

const Login = () => {
  let location = useLocation();
  const { userInfo, dispatch } = useAuth();
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

  const { loggedIn } = userInfo;

  const initializeGoogle = useCallback(() => {
    const handleLogin = ({ credential }) => {
      const { email, name, picture, sub } = decode(credential);
      dispatch(
        treatLoginUserInfo({
          id: sub,
          name,
          email,
          role: "admin",
          token: credential,
          photo: picture,
        })
      );
    };

    const showLoginMenu = () => {
      google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          google.accounts.id.renderButton(
            document.getElementById("loginButton"),
            {
              theme: "outline",
              size: "large",
            }
          );
        }
      });
    };

    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleLogin,
    });

    showLoginMenu();
  }, [dispatch]);

  useEffect(() => {
    if (gsiScriptLoaded) return;

    const initializeGsi = () => {
      if (!window.google || gsiScriptLoaded) return;

      setGsiScriptLoaded(true);
      initializeGoogle();
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      window.google?.accounts.id.cancel();
      document.getElementById("google-client-script")?.remove();
    };
  }, [gsiScriptLoaded, initializeGoogle]);

  return loggedIn ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ my: 3, textAlign: "center" }}>
            <img src="/images/logo.png" alt="logo" />
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <div id="loginButton" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Login;
