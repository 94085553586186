import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GppGoodIcon from "@mui/icons-material/GppGood";
import ogol from "../../icons/ogol.png";
import { NavItem } from "./NavItem";
import { useAuth, accessLevel } from "../../main/AuthProvider";
import capitalizeName from "../../utils/capitalizeName";

const items = [
  {
    href: "/",
    icon: <GppGoodIcon fontSize="small" />,
    title: "Sites bloqueados",
  },
  {
    href: "/users",
    icon: <PersonIcon fontSize="small" />,
    title: "Usuários/Permissões",
  },
];

export const DefaultSidebar = (props) => {
  const { open, onClose } = props;
  const { userInfo, dispatch } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const handleLogout = () => {
    dispatch({ type: "logout" });
  };

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <a href="/">
              <img src={ogol} alt="logo" width={232} />
            </a>
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.04)",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                px: 3,
                py: "11px",
                borderRadius: 1,
              }}
            >
              <div>
                <Typography color="inherit" variant="subtitle1">
                  {capitalizeName(userInfo.name)}
                </Typography>
                <Typography color="neutral.400" variant="body2">
                  Acesso: {accessLevel(userInfo.role)}
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            my: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
              onClose={onClose}
            />
          ))}
        </Box>
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Button
            color="secondary"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={handleLogout}
          >
            Sair
          </Button>
          <Divider sx={{ mt: 1, borderColor: "#2D3748" }} />
          <Typography color="neutral.500" variant="subtitle2">
            Powered by: Augusto Amaral Pereira
          </Typography>
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DefaultSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
