import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import getInitials from "../../utils/getInitials";
import { DeleteForever, Edit } from "@mui/icons-material";
import { accessLevel } from "../../main/AuthProvider";
import capitalizeName from "../../utils/capitalizeName";

const getRoleColor = (role) => {
  switch (role) {
    case "admin":
      return "#FF0000";
    case "moderator":
      return "#FFA500";
    case "user":
      return "#00FF00";
    default:
      return "#000000";
  }
};

const UserListResult = ({ users, onDelete, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card sx={{ minWidth: 1050, overflowX: "auto" }} {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Data de registro</TableCell>
                <TableCell>Nível de acesso</TableCell>
                <TableCell>Editar</TableCell>
                <TableCell>Apagar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(0, limit).map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Avatar src={user.avatarUrl} sx={{ mr: 2 }}>
                        {getInitials(user.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {capitalizeName(user.name)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{user.mail}</TableCell>
                  <TableCell>
                    {new Date(user.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        backgroundColor: "active",
                        color: getRoleColor("admin"),
                        borderColor: "neutral.900",
                        minWidth: 42,
                        marginBottom: 1,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      {`${accessLevel("admin")}`}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Peça ao usuário para alterar os dados">
                      <span>
                        <IconButton disabled>
                          <Edit />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => onDelete(user.id)}>
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage="Linhas por página"
      />
    </Card>
  );
};

UserListResult.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UserListResult;
