import React from "react";

const Profile = () => {
  return (
    <div>
      <h1>
        <a href="https://minecraft.augustoamaral.com/">Fui descoberto</a>
      </h1>
    </div>
  );
};

export default Profile;
