import { useContext, createContext, useReducer, useEffect } from "react";

const initialUserInfo = () => ({
  loggedIn: false,
  id: null,
  name: null,
  email: null,
  role: null,
  token: null,
  photo: null,
});

const userReducer = (_, action) => {
  switch (action.type) {
    case "login":
      return { ...action.payload, loggedIn: true };
    case "logout":
      window.localStorage.removeItem("userInfo");
      return initialUserInfo();
    default:
      throw new Error();
  }
};

const initialState =
  JSON.parse(window.localStorage.getItem("userInfo")) || initialUserInfo();

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userInfo, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }, [userInfo]);

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const treatLoginUserInfo = (userInfo) => ({
  type: "login",
  payload: userInfo,
});

export const accessLevel = (role) => {
  switch (role) {
    case "admin":
      return "Administrador";
    case "moderator":
      return "Moderador";
    case "user":
      return "Usuário";
    default:
      return "Registrado";
  }
};
