import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { object, string } from "yup";
import { Formik } from "formik";

const SubmitSchema = object().shape({
  url: string()
    .url("Digite uma URL válida.")
    .required("Por favor, digite uma URL válida."),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function SiteForm({ open, onClose, data, onSubmit }) {
  return (
    <BootstrapDialog onClose={onClose} open={open}>
      <BootstrapDialogTitle id="editar-criar-site" onClose={onClose}>
        {data ? "Editar site" : "Novo site"}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            url: data?.url || "",
            description: data?.description || "",
          }}
          validationSchema={SubmitSchema}
          onSubmit={(values) => {
            onSubmit(values, data?.id);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <form id="addSite" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  id="url"
                  name="url"
                  label="URL"
                  value={values.url}
                  onChange={handleChange}
                  error={touched.url && Boolean(errors.url)}
                  helperText={touched.url && errors.url}
                  required
                />
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Descrição"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  margin="normal"
                />
              </form>
            );
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="addSite">
          Salvar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
