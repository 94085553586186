import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";

const SitesListToolbar = ({ onOpenForm, isLoading, props }) => (
  <Box sx={{ minWidth: 1050 }} {...props}>
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: -1,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h4">
        Sites bloqueados
      </Typography>
      <Box sx={{ m: 1 }}>
        <Tooltip title="Em breve...">
          <span>
            <Button
              startIcon={<UploadIcon fontSize="small" />}
              sx={{ mr: 1 }}
              disabled
            >
              Importar
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Em breve...">
          <span>
            <Button
              startIcon={<DownloadIcon fontSize="small" />}
              sx={{ mr: 1 }}
              disabled
            >
              Exportar
            </Button>
          </span>
        </Tooltip>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onOpenForm()}
          disabled={isLoading}
        >
          {isLoading ? "Carregando..." : "Adicionar Site"}
        </Button>
      </Box>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              disabled
              placeholder="Buscar sites (Em breve...)"
              variant="outlined"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default SitesListToolbar;
