import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { AppBar, Avatar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuth } from "../../main/AuthProvider";
import { Link } from "react-router-dom";

const DefaultNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

export const DefaultNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;
  const { userInfo } = useAuth();

  return (
    <>
      <DefaultNavbarRoot
        sx={{
          left: {
            lg: 280,
          },
          width: {
            lg: "calc(100% - 280px)",
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Link to="/profile">
            <IconButton>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
                src={userInfo.photo}
              >
                <AccountCircleIcon fontSize="small" />
              </Avatar>
            </IconButton>
          </Link>
        </Toolbar>
      </DefaultNavbarRoot>
    </>
  );
};

DefaultNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
