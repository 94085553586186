import React from "react";
import { theme } from "../theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Routes from "./Routes";
import { AuthProvider } from "./AuthProvider";
import { SnackbarProvider } from "notistack";

const Main = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="main-css">
          <SnackbarProvider maxSnack={3}>
            <Routes />
          </SnackbarProvider>
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default Main;
