import React from "react";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import Login from "./Login";
import NotFound from "./NotFound";
import { useAuth } from "./AuthProvider";
import DefaultLayout from "../components/common/DefaultLayout";

import Profile from "../pages/Profile";
import Sites from "../pages/Sites";
import Users from "../pages/Users";
import Privacy from "../pages/Privacy";

const AuthCheck = (El) => {
  const { userInfo } = useAuth();
  if (!userInfo.loggedIn) {
    return <Login />;
  }
  return (
    <DefaultLayout>
      <El />
    </DefaultLayout>
  );
};

const privateRoutes = [
  { element: Sites, path: "/" },
  { element: Profile, path: "/profile" },
  { element: Users, path: "/users" },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {privateRoutes.map(({ path, element, ...rest }) => (
          <Route
            key={path}
            path={path}
            element={AuthCheck(element)}
            {...rest}
          />
        ))}

        <Route exact path="/login" element={<Login />} />
        <Route exact path="/privacidade" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
