import getToken from "../tools/getToken";

const addUpdateSite = async ({ url, description }, id) => {
  return fetch(`${process.env.REACT_APP_API_URL}/sites${id ? `/${id}` : ""}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    method: id ? "PUT" : "POST",
    body: JSON.stringify({ url, description }),
  }).then((r) => {
    if (r.status === 201 || r.ok) return r.json();
    throw new Error(r.statusText);
  });
};

export default addUpdateSite;
